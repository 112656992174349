<template>
  <vSelect 
    v-model="selectedObject" 
    label="fullName"
    :placeholder="placeholder" 
    :options="options"
    :reduce="reduce"
    :disabled="disabled"
    :readonly="readonly"
    :required="required" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
  props: {
    placeholder: {
      type: String,
      default: () => 'Select a User'
    },
    required: {
      type: Boolean,
      default: () => false
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    selected: {
      default: () => null
    },
    roles: {
      default: () => 'member'
    },
    newUser: {
      default: () => false
    },
    reduce: null
  },
  data () {
    return {
      loaded: false,
      selectedObject: null,
      options: []
    }
  },
  computed: {
    ...mapGetters([
      'userList'
    ])
  },
  methods: {
    ...mapActions([
      'getUser',
      'getUsers',
      'resetUsers'
    ]),
    async getMissingUser () {
      let userResource = this.selectedObject.split('/')
      let user = await this.getUser({
        id: userResource[userResource.length-1]
      })
      return user
    },
    async doGetUsers () {
      if (!this.userList.length) {
        await this.getUsers({
          roles: 'role_' + this.roles,
          businesses: this.currentBusiness.id,
          pagination: false
        })
      }

      let options = [ ...this.userList ]
      // add new user option
      if (this.newUser) {
        let newUser = this.newUser
        if (typeof newUser !== 'object') {
          newUser = { id: newUser, '@id': newUser, fullName: 'New User' }
        }
        options.push(newUser)
      }
      this.options = options

      
    },
    setSelectedObject (val) {
      if (!this.loaded && this.selectedObject === val) return
      this.selectedObject = val
    }
  },
  watch: {
    selected (val) {
      this.setSelectedObject(val)

      // does selected exist in list
      let selectedExists = this.options.find(obj => obj['@id'] === val)
      if (val && typeof selectedExists === 'undefined') {
        // get member and add to list
        this.getMissingUser().then(user => {
          this.options.push(user)
        })
      }
    },
    selectedObject (val) {
      this.$emit('input', val)
    }
  },
  async mounted () {
    await this.doGetUsers()
    this.loaded = true
    this.setSelectedObject(this.selected)
  },
  destroyed () {
    this.resetUsers()
  },
  components: { vSelect }
}
</script>